import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'
// import residentialGates from '../assets/images/residential-gates.jpg'
// import commercialGates from '../assets/images/commercial-gates.jpg'
// import gateservice from '../assets/images/gate-service.jpg'

class GatesServices extends React.Component {
	render() {
		const siteTitle = 'Gate Services - J&J Automatic Service & Design'
		const siteDescription =
			'We Offer Professional Gate Services in Dallas-Fort Worth and Arlington, TX. Call (817) 466-2794 to Get a Free Estimate on Gate Repair or Installation'

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO
					title="Gate Services - J&J Automatic Service & Design"
					description={siteDescription}
				/>
				<div className="container-fluid text-center headline gates-headline">
					<div className="container">
						<h1 className="text-white m-0">
							Gate Services in Dallas-Fort Worth, TX
						</h1>
						<hr />
					</div>
				</div>
				<div className="container mt-5">
					<p className="mx-auto text-center set-width">
						From the design of your gated entry project to the equipment
						selection, at J & J Gates Service and Design, our Sales and Design
						teams will assist in providing the most suitable proposal for your
						gated entry. Our project types include Commercial, Industrial,
						Government, HOA Developments and Residential. We stand firmly behind
						the quality of our installations and are committed to satisfying the
						needs of our customers.{' '}
						<strong>
							<em>
								If you need gate services in Dallas and Fort Worth, TX, call us
								at (817) 466-2794
							</em>
						</strong>
						.
					</p>
				</div>
				<div className=" container gate-svc mt-0">
					<h4 className="text-center mt-0">Gate Services We Provide</h4>
					<hr />
					<p className="mt-2 text-center">
						We offer a variety of custom gates for both homes and places of
						business. Here are some of the gates we offer:
					</p>

					<div className="row gates-row4 text-center mt-3 ">
						<div className="col-md-4 ">
							<Link to="/gates/services/access-systems">
								<div className="gate-svc-cont">
									<h5>Access Systems:</h5>
									<p>
										<em>
											Whether you’re looking for keypad entry, remote control,
											or other access systems, J & J Gates Service and Design
											have you covered.
										</em>
									</p>
								</div>
							</Link>
						</div>
						<div className="col-md-4">
							<Link to="/gates/services/commercial-gate">
								<div className="gate-svc-cont">
									<h5>Commercial Gate Services:</h5>
									<p>
										<em>
											Contact us if you need gate services for your business,
											school, government project, or other commercial property.
										</em>
									</p>
								</div>
							</Link>
						</div>
						<div className="col-md-4">
							<Link to="/gates/services/gate-automation">
								<div className="gate-svc-cont">
									<h5>Gate Automation:</h5>
									<p>
										<em>
											Embrace the convenience and security of automated gate
											systems for your home or business with expert installation
											by J & J Gates Service and Design!
										</em>
									</p>
								</div>
							</Link>
						</div>
					</div>

					<div className="row gates-row4 text-center mt-5">
						<div className="col-md-4">
							<Link to="/gates/services/gate-installation">
								<div className="gate-svc-cont">
									<h5>Gate Installation:</h5>
									<p>
										<em>
											When you need a new gate installed for your property, let
											the experts help you find the right style and type that
											suits your needs and budget.
										</em>
									</p>
								</div>
							</Link>
						</div>
						<div className="col-md-4">
							<Link to="/gates/services/gate-repair">
								<div className="gate-svc-cont">
									<h5>Gate Repair:</h5>
									<p>
										<em>
											Whether we installed your gate or someone else did, we
											will get it back in working order as quickly as possible.
										</em>
									</p>
								</div>
							</Link>
						</div>
						<div className="col-md-4">
							<Link to="/gates/services/residential-services">
								<div className="gate-svc-cont">
									<h5>Residential Gate Services:</h5>
									<p>
										<em>
											From private residences to apartment complexes, we have
											residential gate services to fulfill every need.
										</em>
									</p>
								</div>
							</Link>
						</div>
					</div>

					<div className="text-center mx-auto svc-row-2 d-padding-t d-padding-b set-width">
						<h4 className="m-0">Automatic Gate Maintenance Services</h4>
						<hr />
						<p>
							<strong>
								Along with the installation of any automated gate, you need a
								plan for its continued maintenance
							</strong>
							. Having your gate serviced regularly will ensure your automated
							system gives you years of trouble-free operation. Our experienced
							crew specializes in the repair of automatic gates as well as
							access systems. Our team is dedicated entirely to customer
							satisfaction, and you will not find us cutting corners. We have
							same-day installation and after-hours emergency services available
							if you are in a pinch.
						</p>
						<p>
							We service all major <Link to="/gates/gate-brands/">brands</Link>{' '}
							such as Elite Gate, Viking, LiftMaster, EMX Industrial Solutions,
							and more. Even if your automated gate was not designed or
							installed by <Link to="/">J & J Gates</Link>, we are still able
							and available to maintain your system. If you don’t currently have
							a maintenance plan in place to keep your gate working smoothly,
							let us design one for you today!
						</p>
					</div>
					<div className="set-width text-center mx-auto ">
						<h4 className="m-0">Other Services</h4>
						<hr />

						<h5 className="mt-3">Fence Repair</h5>
						<p>
							Fences need repair for many different reasons. Weather, vehicle
							accident, or falling debris can cause serious damage. No matter
							who installed the fence originally, you can count on J & J Gates
							Service and Design to repair it.
						</p>
						<h5 className="mt-3">Garage Door Repair Services</h5>
						<p>
							In addition to gates and fences, we also work on garage doors. If
							your door needs repair or service, let us know, and we will find a
							solution. We can repair and work on all major brands, even if we
							did not install the system. Call us at for garage door repair in
							Dallas and Fort Worth, including:
						</p>
					</div>

					<div className="row gates-row3 text-center mt-5 other-svc  ">
						<div className="col-md-2 offset-md-1">
							<div className="gate-type-cont">
								<h5>Garage Door Spring Replacement</h5>
							</div>
						</div>
						<div className="col-md-2">
							<div className="gate-type-cont">
								<h5>Automatic Garage Door Opener Installation</h5>
							</div>
						</div>
						<div className="col-md-2">
							<div className="gate-type-cont">
								<h5>Garage Door Section Replacement</h5>
							</div>
						</div>
						<div className="col-md-2 ">
							<div className="gate-type-cont">
								<h5>Garage Door Section Replacement</h5>
							</div>
						</div>
						<div className="col-md-2">
							<div className="gate-type-cont">
								<h5>Tune-Ups</h5>
							</div>
						</div>
					</div>

					<div className="row gates-row3 text-center mt-5 other-svc"></div>
					<p className="col-md-9 offset-md-2 mt-0">
						For immediate help, call us at{' '}
						<a href="tel:8174662794">817-466-2794</a> for emergency services!
						Our emergency services are 1.5 times the regular rate.
					</p>
				</div>
			</Layout>
		)
	}
}

export default GatesServices

export const GatesServicesQuery = graphql`
	query GatesServicesQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
